import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { SmartLink } from '../SmartLink';
import style from './Breadcrumb.module.scss';

const Breadcrumb = ({ items, display, isInner }) => {
    return (
        <div className={[style.el, 'breadcrumb', isInner ? style.inner : null].join(' ')}>
            <div className={[style.container, 'container'].join(' ')}>
                {items.map((item, i) => {
                    return (
                        <Fragment key={item.text}>
                            {i > 0 && <span> / </span>}
                            <SmartLink href={i < items.length - 1 ? item.path : ''}>{item.text}</SmartLink>
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};

Breadcrumb.propTypes = {
    items: PropTypes.array,
    display: PropTypes.bool,
    isInner: PropTypes.bool,
};
Breadcrumb.defaultProps = {
    items: [
        {
            text: 'Home',
            path: '/',
        },
        {
            text: 'People',
            path: '/',
        },
        {
            text: 'Meet Our People',
            path: '/',
        },
    ],
    isInner: false,
};
export default Breadcrumb;
