import { isArray } from 'lodash';
import qs from 'qs';

const setQueryStringWithoutPageReload = (qsValue, router) => {
    let newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
    if (qsValue !== '?') {
        newUrl += qsValue;
    }

    if (router) {
        router.push(newUrl, newUrl, { shallow: true, scroll: false });
    } else {
        window.history.pushState({ path: newUrl }, '', newUrl);
    }
};

export const removeQueryStringValue = (key, queryString = '', router = null) => {
    if (!queryString && typeof window !== 'undefined') {
        queryString = window.location.search;
    }
    const values = qs.parse(queryString.replace('?', ''));
    delete values[key];
    const newQsValue = qs.stringify({ ...values });
    setQueryStringWithoutPageReload(`?${newQsValue}`, router);
};

export const setQueryStringValue = (key, value, queryString = '', router = null) => {
    if (!queryString && typeof window !== 'undefined') {
        queryString = window.location.search;
    }
    const values = qs.parse(queryString.replace('?', ''));
    const newQsValue = qs.stringify({ ...values, [key]: value });
    setQueryStringWithoutPageReload(`?${newQsValue}`, router);
};

export const getQueryStringValue = (key, queryString = '', router = null) => {
    let values = {};
    if (!queryString && typeof window !== 'undefined' && !router) {
        queryString = window.location.search;
        values = qs.parse(queryString.replace('?', ''));
    } else if (!queryString && router) {
        values = router.query;
    } else if (queryString) {
        values = qs.parse(queryString.replace('?', ''));
    }
    if (key) {
        return values[key];
    } else {
        return values;
    }
};

export const convertFiltersToObject = selectedFilters => {
    const obj = {};
    selectedFilters.forEach(f => {
        if (f.single) {
            obj[f.type] = f.value;
        } else if (!obj[f.type] && !isArray(f.value)) {
            obj[f.type] = [f.value];
        } else if (!obj[f.type] && isArray(f.value)) {
            obj[f.type] = f.value;
        } else {
            obj[f.type].push(f.value);
        }
    });
    return obj;
};

export const convertFiltersToString = selectedFilters => {
    return JSON.stringify(convertFiltersToObject(selectedFilters));
};

export const convertObjectToFilters = filtersFromQueryString => {
    const result = [];
    if (filtersFromQueryString) {
        Object.entries(filtersFromQueryString).forEach(([key, value]) => {
            if (isArray(value)) {
                value.forEach(v => {
                    const filter = { type: key, value: v, label: v, single: false };
                    result.push(filter);
                });
            } else {
                const filter = { type: key, value: value, label: value, single: true };
                result.push(filter);
            }
        });
    }
    return result;
};

export const getFiltersFromQueryString = (key, queryString = '') => {
    const obj = getQueryStringValue(key, queryString);
    return convertObjectToFilters(obj);
};

export const removeQuery = asPath => {
    return asPath.split('?').shift();
};

/* NEW FILTER METHODS TO CLEAN AND SIMPLIFY FILTER URL CHANGES */
const getQueryParams = (url) => {
    const queryParams = {};
    const queryString = url.split('?')[1];

    if (!queryString) {
        return queryParams; // return empty object if there's no query string
    }

    queryString.split('&').forEach(pair => {
        let [key, value] = pair.split('=');
        key = decodeURIComponent(key);
        value = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        queryParams[key] = value;
    });

    return queryParams;
}

const setQueryStringValueFromVariablesWithoutPageReload = (queryStringVars, router) => {
    let newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
    newUrl += `?${Object.keys(queryStringVars).map(key => `${key}=${queryStringVars[key]}`).join('&')}`;

    if (router) {
        router.push(newUrl, newUrl, { shallow: true, scroll: false });
    } else {
        window.history.pushState({ path: newUrl }, '', newUrl);
    }
}

// Filter expected format: filters=office-london--strategy-private_equity,buyout (Office = London, Strategy = Private Equity & Buyout)
export const addVariableToQuery = (key, values, queryString = undefined, router = null) => {
    if (!values || !Object.keys(values).length) {
        const qsVariables = getQueryParams(queryString || window.location.href);
        delete qsVariables[key];
        setQueryStringValueFromVariablesWithoutPageReload(qsVariables, router);
    } else {
        const qsVariables = getQueryParams(queryString || window.location.href);

        let variableString = '';
        Object.keys(values).forEach(key => {
            const filtersValue = Array.isArray(values[key]) ? values[key].join(',') : values[key];
            variableString += `${key}-${filtersValue.replace(/ /g, '_')}--`;
        });
        variableString = variableString.substring(0, variableString.length - 2);
        qsVariables[key] = variableString;
        setQueryStringValueFromVariablesWithoutPageReload(qsVariables, router);
    }
}

export const getVariableFromQueryString = (key, queryString = '') => {
    const qsVariables = getQueryParams(queryString || window.location.href);
    const value = qsVariables[key];

    if (!value) return [];

    const variableObjects = [];
    const variableTypes = value.split('--');
    variableTypes.forEach(variableType => {
        const variableSplit = variableType.split('-');
        const variableKey = variableSplit[0];
        const variableOptions = variableSplit[1].split(',');
        variableOptions.forEach(variableOption => {
            if (variableOption) variableObjects.push({ type: variableKey, value: variableOption.replaceAll('_', ' '), label: variableOption.replaceAll('_', ' '), single: variableKey === 'keywords' });
        });
    });

    return variableObjects;
}
