import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLottie } from 'lottie-react';
import style from './LottieAnimation.module.scss';

const LottieAnimation = ({ json, playImmediately = false, loop = false }) => {
    // Use state to trigger a refresh on Intersection Observer (if required)
    const elRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(playImmediately);

    const getView = () => {
        try {
            const parsedJSON = typeof (json) === 'object' ? json : JSON.parse(json);
            const options = {
                animationData: parsedJSON,
                loop: loop
            };
            const { View } = useLottie(options);
            return View;
        } catch (e) {
            return <></>;
        }
    }

    useEffect(() => {
        if (!playImmediately) {
            const animate = (entries) => {
                if (entries[0].isIntersecting) {
                    setIsPlaying(true);
                    observer.disconnect();
                }
            }

            const options = {
                rootMargin: '0px',
                threshold: 1.0,
            };
            const observer = new IntersectionObserver(animate, options);
            observer.observe(elRef.current);
        }
    }, []);

    return (
        <div className={style.el + ' ' + (isPlaying ? style.visible : '')} ref={elRef}>
            {getView()}
        </div>
    );
};

LottieAnimation.propTypes = {
    json: PropTypes.string,
    playImmediately: PropTypes.bool,
    loop: PropTypes.bool
};
export default LottieAnimation;

LottieAnimation.defaultProps = {};
