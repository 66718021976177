import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isIOS } from 'react-device-detect';

import style from './CustomSelect.module.scss';

const CustomSelect = ({ active, data, defaultTitle, handleSelect, colourVariant }) => {
    const compRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState(defaultTitle);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        function outsideClick (e) {
            if (!compRef.current?.contains(e.target)) {
                setIsOpen(false);
            }
        }
        if (!isIOS) {
            window.addEventListener('click', outsideClick);
        }
        return () => {
            if (!isIOS) {
                window.removeEventListener('click', outsideClick);
            }
        };
    });

    const isFirstRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        const result = data.filter(item => item.value === active);
        if (active !== result) {
            setTitle(result[0].label);
        }
    }, [active]);

    return (
        <div className={style.el + ' ' + style[colourVariant]} ref={compRef}>
            <div className={style.button}>
                <button type='button' onClick={toggleOpen}>
                    <span className={style.title}>{title}</span>
                    <span className={[style.icon, isOpen ? style.open : '', 'icon-arrow-up'].join(' ')} />
                </button>
                <select name='page' onChange={handleSelect} defaultValue={active}>
                    {data.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            <div
                className={[style.options, isOpen ? style.active : style.closed].join(' ')}
                style={{ '--var-mh': `${38 * data.length + 10}px` }}
            >
                <div className={style.optionsWrap}>
                    {data.map(option => {
                        return (
                            <div
                                key={option.value}
                                className={[style.optionItem, active === option.value ? style.active : ''].join(' ')}
                            >
                                <button
                                    type='button'
                                    onClick={() => {
                                        handleSelect(option.value);
                                        setIsOpen(false);
                                    }}
                                >
                                    <span className={style.checkbox} />
                                    <span className={style.label}>{option.label}</span>
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

CustomSelect.propTypes = {
    active: PropTypes.string,
    data: PropTypes.array,
    defaultTitle: PropTypes.string,
    handleSelect: PropTypes.func,
    colourVariant: PropTypes.string,
};

export default CustomSelect;
